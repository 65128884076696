@charset "UTF-8";

@font-face {
	font-family: "clevericons";
	src:url("../../fonts/clevericons.eot");
	src:url("../../fonts/clevericons.eot?#iefix") format("embedded-opentype"),
	url("../../fonts/clevericons.woff") format("woff"),
	url("../../fonts/clevericons.ttf") format("truetype"),
	url("../../fonts/clevericons.svg#clevericons") format("svg");
	font-weight: normal;
	font-style: normal;

}

[data-icon]:before {
	font-family: "clevericons" !important;
	content: attr(data-icon);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="clevericon-"]:before,
[class*=" clevericon-"]:before {
	font-family: "clevericons" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.clevericon-arrow-down:before {
	content: "\e000";
}
.clevericon-arrow-left:before {
	content: "\e001";
}
.clevericon-arrow-right:before {
	content: "\e002";
}
.clevericon-arrow-up:before {
	content: "\e003";
}
.clevericon-basket:before {
	content: "\e004";
}
.clevericon-bullet:before {
	content: "\e005";
}
.clevericon-bullet-diamond:before {
	content: "\e006";
}
.clevericon-bullet-square:before {
	content: "\e007";
}
.clevericon-calendar:before {
	content: "\e008";
}
.clevericon-cherry-bite:before {
	content: "\e009";
}
.clevericon-chevron-down:before {
	content: "\e00a";
}
.clevericon-chevron-left:before {
	content: "\e00b";
}
.clevericon-chevron-light-down:before {
	content: "\e00c";
}
.clevericon-chevron-light-left:before {
	content: "\e00d";
}
.clevericon-chevron-light-right:before {
	content: "\e00e";
}
.clevericon-chevron-light-up:before {
	content: "\e00f";
}
.clevericon-chevron-right:before {
	content: "\e010";
}
.clevericon-chevron-up:before {
	content: "\e011";
}
.clevericon-cross:before {
	content: "\e012";
}
.clevericon-cross-rounded:before {
	content: "\e013";
}
.clevericon-cross-sketched:before {
	content: "\e014";
}
.clevericon-diamond:before {
	content: "\e015";
}
.clevericon-diamond-alt:before {
	content: "\e016";
}
.clevericon-email:before {
	content: "\e017";
}
.clevericon-facebook:before {
	content: "\e018";
}
.clevericon-favourite-add:before {
	content: "\e019";
}
.clevericon-favourite-remove:before {
	content: "\e01a";
}
.clevericon-favourite-view:before {
	content: "\e01b";
}
.clevericon-foursquare:before {
	content: "\e01c";
}
.clevericon-google-plus:before {
	content: "\e01d";
}
.clevericon-home-outline:before {
	content: "\e01e";
}
.clevericon-information:before {
	content: "\e01f";
}
.clevericon-instagram:before {
	content: "\e020";
}
.clevericon-linkedin:before {
	content: "\e021";
}
.clevericon-message:before {
	content: "\e022";
}
.clevericon-mouse:before {
	content: "\e023";
}
.clevericon-phone:before {
	content: "\e024";
}
.clevericon-rss:before {
	content: "\e025";
}
.clevericon-search:before {
	content: "\e026";
}
.clevericon-spotify:before {
	content: "\e027";
}
.clevericon-star:before {
	content: "\e028";
}
.clevericon-suitcase:before {
	content: "\e029";
}
.clevericon-thomas-clock:before {
	content: "\e02a";
}
.clevericon-tick:before {
	content: "\e02b";
}
.clevericon-tick-rounded:before {
	content: "\e02c";
}
.clevericon-triangle-arrow-up:before {
	content: "\e02d";
}
.clevericon-triangle-rounded-arrow-down:before {
	content: "\e02e";
}
.clevericon-triangle-rounded-arrow-left:before {
	content: "\e02f";
}
.clevericon-triangle-rounded-arrow-right:before {
	content: "\e030";
}
.clevericon-triangle-rounded-arrow-up:before {
	content: "\e031";
}
.clevericon-tripadvisor:before {
	content: "\e032";
}
.clevericon-tumblr:before {
	content: "\e033";
}
.clevericon-twitter:before {
	content: "\e034";
}
.clevericon-wifi:before {
	content: "\e035";
}
.clevericon-wifi-v2:before {
	content: "\e036";
}
.clevericon-governance:before {
	content: "\e037";
}
.clevericon-hammer:before {
	content: "\e038";
}
.clevericon-home:before {
	content: "\e039";
}
.clevericon-wordpress:before {
	content: "\e03a";
}
.clevericon-youtube:before {
	content: "\e03b";
}
.clevericon-people:before {
	content: "\e03c";
}
.clevericon-id:before {
	content: "\e03d";
}
.clevericon-school:before {
	content: "\e03e";
}
.clevericon-pin:before {
	content: "\e03f";
}
.clevericon-pinterest:before {
	content: "\e040";
}
.clevericon-print:before {
	content: "\e041";
}
.clevericon-tick-sketched:before {
	content: "\e042";
}
.clevericon-triangle-arrow-down:before {
	content: "\e043";
}
.clevericon-triangle-arrow-left:before {
	content: "\e044";
}
.clevericon-print-alt:before {
	content: "\e045";
}
.clevericon-triangle-arrow-right:before {
	content: "\e046";
}
